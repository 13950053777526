.header {
  position: relative;
  &.top {
    height: 100vh;
    // @include media(xs) {
    //   height: 100%;
    // }
  }
  @include media(sm) {
    height: 100%;
  }
  overflow: hidden;
  $this: &;
  &__hero {
    position: relative;
  }
  &.top &__hero {
    padding-top: 52.855%;
    @include media(xs,max) {
      height: calc(100% - 140px);
      max-height: 730px;
    }
    // .top__img {
    //   @include media(xs,max) {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     display: flex;
    //     align-items: flex-end;
    //     width: 100%;
    //     height: auto;
    //     max-height: 730px;
    //   }
    // }
  }
  &:not(.top) &__hero {
    background: color(black,400);
    @include media(sm) {
      height: 275px;
      > picture {
        width: 100%;
        height: 275px;
        > img {
          position: relative;
          left: 50%;
          width: auto;
          max-width: initial;
          height: 100%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &__title {
    @include position;
    @include fz(30,40);
    color: color(white);
  }
}