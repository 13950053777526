@charset "UTF-8";
/* Pluguins
==================== */
/* Modules
==================== */
* {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  word-wrap: inherit;
  border: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  line-height: 1;
  letter-spacing: 0.15em;
  word-wrap: break-word;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
_:-ms-fullscreen, :root html {
  letter-spacing: 0.05em;
}

body {
  overflow-x: hidden;
  text-align: left;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: 1.6;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
}

main,
aside,
footer,
figure,
figcaption,
article,
section {
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 16px;
}

input {
  border-radius: 0;
}

button {
  background-color: transparent;
}
@media (hover: hover) {
  button {
    cursor: pointer;
  }
}
_:-ms-fullscreen, :root button {
  cursor: pointer;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

@media (min-width: 600px) and (hover: hover) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
_:-ms-fullscreen, :root a[href^="tel:"] {
  pointer-events: none;
}

[class$=__iframe] > iframe, [class*="__iframe "] > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.iframe::before {
  display: block;
  width: 100%;
  padding-top: 56.25%;
  content: "";
}
.iframe > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 500px) {
  .span {
    display: inline;
  }
}
@media (min-width: 769px) {
  .span {
    display: inline;
  }
}
@media (min-width: 960px) {
  .span {
    display: inline;
  }
}
@media (min-width: 1200px) {
  .span {
    display: inline;
  }
}
@media (max-width: 499.98px) {
  .span {
    display: inline;
  }
}
@media (max-width: 768.98px) {
  .span {
    display: inline;
  }
}
@media (max-width: 959.98px) {
  .span {
    display: inline;
  }
}
@media (max-width: 1199.98px) {
  .span {
    display: inline;
  }
}
body {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
body::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100vw;
  height: 100vh;
  content: "";
  background: url("../img/home/hero_text@2x.png") no-repeat bottom 25px right 75px, url("../img/home/hero_pc.png") no-repeat top left 47%;
  background-size: 196px 58px, cover;
  transform: translateZ(0);
}
@media (max-width: 768.98px) {
  body::before {
    background: url("../img/home/hero_text@2x.png") no-repeat bottom 15px right 15px, url("../img/home/hero_sp.png") no-repeat center center;
    background-size: 196px 58px, cover;
  }
}
body.top {
  background: #c6c4be;
}
body.top main {
  max-width: 1225px;
  padding: 75px 0 100px;
  margin: 0 auto;
  background: url("../img/home/bg.png") no-repeat, url("../img/home/bg-repeat.png") repeat-y;
  background-size: 100% auto;
  box-shadow: 0 7px 11px rgba(0, 0, 0, 0.3);
}
@media (max-width: 1265px) {
  body.top main {
    margin: 0 20px;
  }
}
@media (min-width: 1200px) {
  body.top main {
    background-size: cover;
  }
}
body:not(.top) .bg_color {
  background: url("../img/common/bg.png") repeat-y;
  background-position: top 450px center;
  background-size: 100% auto;
}

.wrapper {
  max-width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
}
.wrapper.top {
  padding: 0 15px;
}

.top_bg_color {
  background-color: #c6c4be;
}

.footer {
  position: relative;
  justify-content: flex-start;
  max-width: 1165px;
  height: 150px;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 115px;
  color: #163257;
  display: flex;
  align-items: center;
}
@media (min-width: 769px) {
  .footer {
    height: 215px;
  }
}
.footer.top {
  margin-top: 0;
}
.footer:not(.top)::before {
  top: 0;
  display: block;
  width: calc(100% - 40px);
  height: 1px;
  content: "";
  background: #163257;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.footer__container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
}
@media (min-width: 769px) {
  .footer__container {
    justify-content: space-between;
  }
}
.footer .ico-footer {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
@media (min-width: 1200px) {
  .footer .ico-footer {
    right: -75px;
  }
}

.address {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}
.address__title {
  font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuProN-W4", "メイリオ", meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.9rem;
  line-height: 1;
  text-align: center;
}
@media (min-width: 769px) {
  .address__title {
    font-size: 2.2rem;
  }
}
.address__title > span {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2.8rem;
  letter-spacing: 0.06em;
}
@media (min-width: 769px) {
  .address__title > span {
    font-size: 3rem;
  }
}
.address__text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
}
@media (min-width: 960px) {
  .address__text {
    font-size: 16px !important;
  }
}
@media (min-width: 500px) {
  .address__text {
    font-size: calc(2 * ((100vw - 500px) / 460) + 14px);
  }
}
.address__text > a {
  margin-left: 15px;
  letter-spacing: 0;
}
.address__copy {
  display: block;
  margin-top: 10px;
  font-size: 1rem;
  text-align: center;
}

.footer-nav {
  position: relative;
  z-index: 1;
  width: 100%;
}
.footer-nav__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 610px;
  margin-left: auto;
}
.footer-nav__item, .footer-nav__item--big {
  flex: 0 0 17.6470588235%;
  max-width: 17.6470588235%;
  font-size: 13.5px;
  text-align: center;
}
@media (min-width: 960px) {
  .footer-nav__item, .footer-nav__item--big {
    font-size: 16px !important;
  }
}
@media (min-width: 769px) {
  .footer-nav__item, .footer-nav__item--big {
    font-size: calc(2.5 * ((100vw - 769px) / 191) + 13.5px);
  }
}
.footer-nav__item--big {
  flex: 0 0 29.4117647059%;
  max-width: 29.4117647059%;
}
.footer-nav__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .footer-nav__link:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .footer-nav__link:hover {
  opacity: 0.7;
}

.footer-nav__link > br {
  display: none;
}
@media (min-width: 769px) {
  .footer-nav__link > br {
    display: inline;
  }
}
@media (min-width: 960px) {
  .footer-nav__link > br {
    display: none;
  }
}
.footer-nav__btn.btnBR {
  width: 50%;
  max-width: 340px;
  margin-top: 40px;
  margin-left: auto;
  color: #fff;
  transition: background-color 0.7s;
}
.footer-nav__btn.btnBR:hover {
  background: #9c8d6f;
  opacity: 1;
}

.gnav {
  position: relative;
  height: 87px;
  background: #f0efec;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 769px) {
  .gnav {
    height: 185px;
  }
}
.gnav.top {
  height: 140px;
}
@media (min-width: 769px) {
  .gnav.top {
    height: 185px;
  }
}
.gnav__container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: #807358;
  transition: transform 0.8s;
  transform: translateX(100%);
}
@media (min-width: 769px) {
  .gnav__container {
    position: static;
    height: auto;
    background: transparent;
    transition: none;
    transform: translate(0);
  }
}
.gnav.is-open .gnav__container {
  transform: translateX(0);
}
.gnav__logo {
  width: 100%;
  max-width: 160px;
  margin: 10px auto 0;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.3);
}
.gnav__list {
  width: 100%;
  margin-top: 30px;
}
@media (min-width: 769px) {
  .gnav__list {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.gnav__item, .gnav__item--offset {
  position: relative;
}
@media (min-width: 769px) {
  .gnav__item, .gnav__item--offset {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
.gnav__item.is-current .ico-svg > use, .is-current.gnav__item--offset .ico-svg > use {
  fill: #807358;
}
@media (min-width: 769px) {
  .gnav__item--offset {
    margin-left: 25%;
  }
}
.gnav__item:not(:last-of-type)::before, .gnav__item--offset:not(:last-of-type)::before {
  bottom: -0.5px;
  display: block;
  width: 25px;
  height: 1px;
  content: "";
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 769px) {
  .gnav__item:not(:last-of-type)::before, .gnav__item--offset:not(:last-of-type)::before {
    display: none;
  }
}
.gnav__link {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 769px) {
  .gnav__link {
    flex-direction: column;
    height: 100px;
  }
}
.gnav__link .ico-svg {
  margin: 0 auto;
}
@media (max-width: 768.98px) {
  .gnav__link .ico-svg {
    display: none;
  }
}
.gnav__link .ico-home {
  margin-top: 5px;
}
.gnav__link .ico-notice {
  margin-top: 8px;
}
.gnav__link .ico-about {
  margin-top: 15px;
}
.gnav__link .ico-anecdote {
  margin-top: 2px;
}
.gnav__link .ico-contact {
  margin-top: 11px;
}
.gnav__link:hover .ico-svg > use {
  fill: #807358;
}
.gnav__title {
  display: block;
  font-size: 2rem;
  color: #fff;
  text-align: center;
}
@media (min-width: 769px) {
  .gnav__title {
    margin-top: auto;
    font-size: 1.4rem;
    color: #000;
  }
}
.gnav__title > br {
  display: none;
}
@media (min-width: 769px) {
  .gnav__title > br {
    display: inline;
  }
}
@media (min-width: 960px) {
  .gnav__title > br {
    display: none;
  }
}

.logo {
  top: 25px;
  z-index: 1;
  width: 200px;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 769px) {
  .logo {
    width: 25%;
    max-width: 280px;
  }
}

.gnav-inner {
  width: 100%;
  max-width: 1200px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row__gap {
  min-width: 0;
}

.col-1 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-2 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-3 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-4 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-6 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-7 {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.col-8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-9 {
  flex: 0 0 11.1111111111%;
  max-width: 11.1111111111%;
}

.col-10 {
  flex: 0 0 10%;
  max-width: 10%;
}

.col-11 {
  flex: 0 0 9.0909090909%;
  max-width: 9.0909090909%;
}

.col-12 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

@media (min-width: 500px) {
  .col-xs-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xs-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xs-6 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xs-7 {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .col-xs-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-xs-9 {
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .col-xs-10 {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .col-xs-11 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .col-xs-12 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
}
@media (min-width: 769px) {
  .col-sm-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-sm-6 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-7 {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .col-sm-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-sm-9 {
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .col-sm-10 {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .col-sm-11 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .col-sm-12 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
}
@media (min-width: 960px) {
  .col-md-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-md-6 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-7 {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .col-md-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-md-9 {
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .col-md-10 {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .col-md-11 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .col-md-12 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-lg-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-3 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-lg-6 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-7 {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .col-lg-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-lg-9 {
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .col-lg-10 {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .col-lg-11 {
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .col-lg-12 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
}
.hamburger {
  position: fixed;
  top: 16.5px;
  right: 11px;
  z-index: 10000;
  width: 55px;
  height: 55px;
  cursor: pointer;
  outline: none;
}
.hamburger__box {
  position: relative;
  display: block;
  width: 40px;
  height: 25px;
  margin: 0 auto;
  background: transparent;
}
.hamburger__text {
  width: 100%;
  margin-top: 6px;
  font-size: 12px;
  line-height: 1;
  color: #163257;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger__text:empty {
  display: none;
}
.hamburger.is-open .hamburger__text {
  color: #fff;
}
.hamburger__line {
  display: block;
  width: 32px;
  height: 4px;
  background: #163257;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, margin-top 0.3s 0.3s ease-in-out;
}
.hamburger__line--01 {
  margin-top: 10px;
}
.hamburger__line--03 {
  margin-top: -10px;
}

.is-open.hamburger .hamburger__line--01, .is-open.hamburger .hamburger__line--03 {
  margin-top: 0;
  pointer-events: auto;
  background: #fff;
  transition: transform 0.3s 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}
.is-open.hamburger .hamburger__line--01 {
  transform: translate(-50%, -50%) rotate(45deg) scaleX(1.2);
}
.is-open.hamburger .hamburger__line--02 {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0);
}
.is-open.hamburger .hamburger__line--03 {
  transform: translate(-50%, -50%) rotate(-45deg) scaleX(1.2);
}

.header {
  position: relative;
  overflow: hidden;
}
.header.top {
  height: 100vh;
}
@media (min-width: 769px) {
  .header {
    height: 100%;
  }
}
.header__hero {
  position: relative;
}
.header.top .header__hero {
  padding-top: 52.855%;
}
@media (max-width: 499.98px) {
  .header.top .header__hero {
    height: calc(100% - 140px);
    max-height: 730px;
  }
}
.header:not(.top) .header__hero {
  background: #0b0b0b;
}
@media (min-width: 769px) {
  .header:not(.top) .header__hero {
    height: 275px;
  }
  .header:not(.top) .header__hero > picture {
    width: 100%;
    height: 275px;
  }
  .header:not(.top) .header__hero > picture > img {
    position: relative;
    left: 50%;
    width: auto;
    max-width: initial;
    height: 100%;
    transform: translateX(-50%);
  }
}
.header__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}
@media (min-width: 960px) {
  .header__title {
    font-size: 40px !important;
  }
}
@media (min-width: 500px) {
  .header__title {
    font-size: calc(10 * ((100vw - 500px) / 460) + 30px);
  }
}

.btnSKW, .btnSK, .btnBL, .btnBR.arrowWH, .btnBR {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 60px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .btnSKW:hover, .btnSK:hover, .btnBL:hover, .btnBR:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .btnSKW:hover, :root .btnSK:hover, :root .btnBL:hover, :root .btnBR:hover {
  opacity: 0.7;
}

.btnBR {
  color: #fff !important;
  background-color: #807358;
  transition: all 0.3s;
}
.btnBR:hover {
  color: #807358 !important;
  background-color: #fff !important;
  border: 1px solid #807358;
  opacity: 1 !important;
  /* 引数は矢印の色 */
}
.btnBR:hover::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.btnBR.arrowWH {
  color: #fff !important;
  background-color: #807358;
  transition: all 0.3s;
}
.btnBR.arrowWH:hover {
  color: #807358 !important;
  background-color: #fff !important;
  border: 1px solid #807358;
  opacity: 1 !important;
  /* 引数は矢印の色 */
}
.btnBR.arrowWH:hover::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid #807358 !important;
  border-right: 1px solid #807358 !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.btnBL {
  color: #fff !important;
  background-color: #163257;
  transition: all 0.3s;
}
.btnBL:hover {
  color: #163257 !important;
  background-color: #fff !important;
  border: 1px solid #163257;
  opacity: 1 !important;
  /* 引数は矢印の色 */
}
.btnBL:hover::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.btnSK {
  border: 1px solid black;
  color: #000 !important;
  background-color: transparent;
  transition: all 0.3s;
}
.btnSK:hover {
  color: #fff !important;
  background-color: #163257 !important;
  border: 1px solid #fff;
  opacity: 1 !important;
  /* 引数は矢印の色 */
}
.btnSK:hover::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.btnSKW {
  border: 1px solid #fff;
  color: #fff !important;
  background-color: transparent;
  transition: all 0.3s;
}
.btnSKW:hover {
  color: #807358 !important;
  background-color: #fff !important;
  border: 1px solid #807358;
  opacity: 1 !important;
  /* 引数は矢印の色 */
}
.btnSKW:hover::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid #807358 !important;
  border-right: 1px solid #807358 !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.arrowWH {
  /* 引数は矢印の色 */
}
.arrowWH::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.arrowBK {
  /* 引数は矢印の色 */
}
.arrowBK::after {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 10px;
  height: 10px;
  content: "";
  border-top: 1px solid #000 !important;
  border-right: 1px solid #000 !important;
  transform: translateY(-50%) rotateZ(45deg);
}

.prevBtn, .nextBtn {
  position: absolute;
  top: 50%;
  z-index: 100000;
  width: 25px;
  height: 25px;
  background: rgba(22, 50, 87, 0.96);
  transform: translateY(-50%);
}
@media (min-width: 769px) {
  .prevBtn, .nextBtn {
    top: 50%;
    width: 50px;
    height: 50px;
  }
}
.prevBtn::before, .nextBtn::before {
  position: absolute;
  top: 50%;
  display: block;
  width: 12.5px;
  height: 12.5px;
  content: "";
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}
@media (min-width: 769px) {
  .prevBtn::before, .nextBtn::before {
    width: 25px;
    height: 25px;
  }
}

.prevBtn {
  left: 16px;
}
@media (min-width: 769px) {
  .prevBtn {
    left: 20px;
  }
}
.prevBtn::before {
  left: 60%;
  transform: translate(-50%, -50%) rotate(135deg);
}

.nextBtn {
  right: 15px;
}
@media (min-width: 769px) {
  .nextBtn {
    right: 20px;
  }
}
.nextBtn::before {
  left: 40%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.card__img {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.card__img::before {
  display: block;
  width: 100%;
  padding-top: 56.25%;
  content: "";
}
.card__img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card__btn {
  margin-top: auto;
}

input {
  width: 100%;
  max-width: 290px;
  height: 40px;
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  max-width: 340px;
  height: 120px;
  border: 1px solid #ccc;
}

.form__btn {
  width: 100%;
  max-width: 305px;
  height: 60px;
  margin: 0 auto;
  margin-top: 200px;
  font-size: 2rem;
}
@media (min-width: 769px) {
  .form__btn {
    max-width: 670px;
  }
}
@media (min-width: 500px) {
  .form__btn {
    margin-top: 100px;
  }
}
.form .wpcf7c-btn-back {
  margin-top: 30px;
}

.naname-heading {
  position: relative;
  justify-content: flex-end;
  width: calc(100% - 130px);
  height: 65px;
  margin: 0 0 60px;
  color: #fff;
  background-color: #163257;
  display: flex;
  align-items: center;
}
.naname-heading-outer {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}
@media (min-width: 769px) {
  .naname-heading {
    width: calc(50% - 130px);
    height: 90px;
    margin-right: calc(50% + 20px);
  }
}
.naname-heading__text {
  display: block;
  width: 100%;
  font-size: 29px;
  padding-left: 20px;
}
@media (min-width: 960px) {
  .naname-heading__text {
    font-size: 40px !important;
  }
}
@media (min-width: 500px) {
  .naname-heading__text {
    font-size: calc(11 * ((100vw - 500px) / 460) + 29px);
  }
}
@media (min-width: 769px) {
  .naname-heading__text {
    max-width: 430px;
  }
}
.naname-heading::after {
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  content: "";
  border-color: #163257 transparent transparent transparent;
  border-style: solid;
  border-width: 65px 50px 0 0;
}
@media (min-width: 769px) {
  .naname-heading::after {
    border-width: 90px 110px 0 0;
  }
}

.ico-svg {
  display: block;
}
.ico-svg > use {
  fill: #163257;
  transition: fill 0.7s;
}
.ico-home {
  width: 55px;
  height: 55px;
}
.ico-notice {
  width: 78px;
  height: 50px;
}
.ico-about {
  width: 87px;
  height: 44px;
}
.ico-anecdote {
  width: 57px;
  height: 60px;
}
.ico-disciple {
  width: 56px;
  height: 64px;
}
.ico-contact {
  width: 82px;
  height: 46px;
}
.ico-footer {
  width: 110px;
  height: 118px;
}
@media (min-width: 769px) {
  .ico-footer {
    width: 185px;
    height: 200px;
  }
}
.ico-footer > use {
  fill: #fff;
}

.page-container {
  margin: 50px 0;
}
.page-container_heading {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container_text {
  font-size: 16px;
}

.page-inner {
  padding: 0 20px;
  margin: 50px auto;
}
.page-inner_heading {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-inner_text {
  font-size: 16px;
}

.keifu_img-IE {
  display: none;
}
_:-ms-fullscreen, :root .keifu_img-IE {
  display: block;
}

.about_img {
  justify-content: center;
}
.about_img.row {
  margin: -20px 0 0 -20px;
}
.about_img.row > .row__gap {
  padding: 20px 0 0 20px;
}
@media (max-width: 928px) {
  .about_img-big {
    width: 100%;
  }
}
@media (max-width: 928px) {
  .about_img-small {
    width: 50%;
  }
}

.about-text_colums {
  font-size: 14px;
  columns: 1;
  text-align: justify;
}
@media (min-width: 769px) {
  .about-text_colums {
    columns: 2;
  }
}

.font-italic {
  font-style: italic;
  -webkit-transform: rotate(0.25deg);
  transform: rotate(0.25deg);
}

.about-caption {
  font-size: 13px;
}

.anecdote .decoration {
  position: relative;
  margin-top: 70px;
}
.anecdote .decoration::before {
  position: absolute;
  top: -49px;
  left: 50%;
  display: block;
  width: 39px;
  height: 30px;
  content: "";
  background-image: url(../img/anecdote/decoration01.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateX(-50%);
}
@media (min-width: 769px) {
  .anecdote .decoration {
    margin-top: 90px;
  }
  .anecdote .decoration::before {
    top: -71px;
    width: 68px;
    height: 53px;
  }
}
.anecdote .anecdote-explanation {
  padding-bottom: 35px;
  font-size: 16px;
}
.anecdote .anecdote_pic-box .anecdote-row {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 769px) {
  .anecdote .anecdote_pic-box .anecdote-row {
    margin-top: -20px;
    margin-left: -20px;
  }
}
.anecdote .anecdote-col {
  margin-top: 20px;
}
@media (min-width: 769px) {
  .anecdote .anecdote-col {
    width: calc(50% - 20px);
    margin-top: 20px;
    margin-left: 20px;
  }
}
@media (min-width: 960px) {
  .anecdote .anecdote-col {
    width: calc(33.3333% - 20px);
    margin-top: 20px;
    margin-left: 20px;
  }
}
.anecdote .anecdote-col :hover {
  color: rgba(255, 255, 255, 0.54);
}
.anecdote .anecdote_pic-margin {
  margin-top: 15px;
}
@media (min-width: 769px) {
  .anecdote .anecdote_pic-margin {
    margin-top: 0;
  }
}
.anecdote .modal__outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(120, 121, 124, 0.7);
}
.anecdote .modal__outer.is-open {
  display: block;
}
.anecdote .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  width: calc(100% - 30px);
  max-width: 1140px;
  padding: 55px 27px 20px;
  margin: 0 auto;
  background: rgba(22, 50, 87, 0.96);
  transform: translate(-50%, -50%);
  overflow: auto;
  height: 100vh;
}
@media (min-width: 960px) {
  .anecdote .modal {
    width: 100%;
    padding: 50px 100px 40px;
  }
}
.anecdote .modal__content {
  position: relative;
  display: none;
  width: 100%;
  max-width: 1140px;
}
.anecdote .modal__content.is-active {
  display: flex;
  flex-wrap: wrap;
}
.anecdote .modal__img {
  width: 100%;
}
.anecdote .modal__img > img {
  max-height: 600px;
  object-fit: contain;
}
.anecdote .modal__inner {
  padding-top: 20px;
  padding-left: 0;
  color: white;
}
.anecdote .modal__title {
  font-size: 18px;
  font-weight: 600;
}
.anecdote .modal__text {
  padding-top: 20px;
  font-size: 14px;
}
.anecdote .js-modal-open {
  cursor: pointer;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .anecdote .js-modal-open:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .anecdote .js-modal-open:hover {
  opacity: 0.7;
}

.anecdote .modal-close-button {
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 50px;
  height: 60px;
  padding-top: 40px;
  font-size: 1.2rem;
  color: white;
}
@media (min-width: 960px) {
  .anecdote .modal-close-button {
    top: 50px;
    right: 30px;
    padding-top: 40px;
  }
}
.anecdote .modal-close-button::before, .anecdote .modal-close-button::after {
  position: absolute;
  top: 30px;
  left: 10px;
  display: block;
  width: 30px;
  height: 3px;
  content: "";
  background: white;
  transform: rotate(-45deg);
}
@media (min-width: 960px) {
  .anecdote .modal-close-button::before, .anecdote .modal-close-button::after {
    top: 20px;
    left: -1px;
    width: 50px;
  }
}
.anecdote .modal-close-button::after {
  transform: rotate(-135deg);
}

.contact {
  font-size: 1.4rem;
}
.contact__title {
  margin-top: 30px;
  margin-bottom: 45px;
}
.contact__title > span {
  font-size: 22px;
}
@media (min-width: 960px) {
  .contact__title > span {
    font-size: 39px !important;
  }
}
@media (min-width: 500px) {
  .contact__title > span {
    font-size: calc(17 * ((100vw - 500px) / 460) + 22px);
  }
}
.contact__list {
  padding-bottom: 40px;
  line-height: 1.6;
  border-bottom: 2px solid #807358;
}
.contact__item > a {
  color: #163257;
  text-decoration: underline;
}

.contact-form {
  margin-top: 90px;
}
.contact-form__head {
  margin-bottom: 30px;
}
.contact-form__title {
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
}
@media (min-width: 960px) {
  .contact-form__title {
    font-size: 14px !important;
  }
}
@media (min-width: 500px) {
  .contact-form__title {
    font-size: calc(-2 * ((100vw - 500px) / 460) + 16px);
  }
}
.contact-form__title .color-red {
  margin-left: 10px;
}
@media (min-width: 500px) {
  .contact-form__title {
    float: left;
  }
}
.contact-form__text {
  margin-bottom: 10px;
}
@media (min-width: 500px) {
  .contact-form__text {
    padding-left: 125px;
    margin-bottom: 30px;
  }
}
@media (min-width: 500px) {
  .contact-form .form__textarea {
    width: 100%;
    max-width: 100%;
  }
}

input, textarea {
  padding: 0.3em 0.5em;
}

.disciple dt {
  float: left;
  clear: both;
  font-size: 16px;
}
@media (max-width: 768.98px) {
  .disciple dt {
    float: none;
    margin-bottom: 20px;
    clear: none;
  }
}
.disciple dd {
  padding-left: 15rem;
  margin-bottom: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 2.1428571429;
  text-indent: 0;
}
@media (max-width: 768.98px) {
  .disciple dd {
    padding: 0 10px;
  }
}
.disciple dd p {
  line-height: 2.1428571429;
}
.disciple .keiko-tel_form {
  padding: 30px;
  background-color: #fff;
  border: 5px solid #807358;
}
.disciple .keiko-tel_form-btn {
  color: #807358;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .disciple .keiko-tel_form-btn:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .disciple .keiko-tel_form-btn:hover {
  opacity: 0.7;
}

.disciple .keiko-tel_form .tel-link {
  margin-top: 25px;
}
.disciple .keiko-tel_form .tel-link_num {
  transition: opacity 0.7s;
  font-size: 25px;
  line-height: 1;
}
@media (hover: hover) {
  .disciple .keiko-tel_form .tel-link_num:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .disciple .keiko-tel_form .tel-link_num:hover {
  opacity: 0.7;
}

@media (max-width: 768.98px) {
  .kengaku-form {
    display: flex;
    justify-content: center;
  }
  .kengaku-form_btn {
    font-size: 1vw;
  }
}

.list-indent {
  position: relative;
  padding-left: 2rem;
}
.list-indent::before {
  position: absolute;
  left: 0;
  content: "・";
}

.underline {
  text-decoration: underline;
}

.notice {
  font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuProN-W4", "メイリオ", meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
.notice .wrapper {
  display: flex;
  padding-top: 60px;
}
@media (max-width: 768.98px) {
  .notice .wrapper {
    flex-direction: column;
  }
}
.notice-label {
  display: inline-block;
  padding: 0 1em;
  margin-bottom: 10px;
  font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuProN-W4", "メイリオ", meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  background-color: #807358;
}
.notice-date {
  display: inline-block;
  font-family: "ヒラギノ角ゴ Pro W3", "HiraKakuProN-W4", "メイリオ", meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.2rem;
  color: #78797c;
}
.notice-btns {
  display: flex;
  justify-content: center;
}
.notice-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 60px;
  font-size: 1.4rem;
  border: 1px solid #163257;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .notice-btn:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .notice-btn:hover {
  opacity: 0.7;
}

.notice-btn::before, .notice-btn::after {
  position: relative;
  bottom: -2px;
  display: inline-block;
  width: 6px;
  height: 6px;
  content: "";
}
.notice-btn.prev {
  margin-right: 10px;
}
.notice-btn.prev::before {
  margin-right: 0.5em;
  border-top: 1px solid #163257;
  border-left: 1px solid #163257;
  transform: rotate(-45deg) translateY(-50%);
}
.notice-btn.next::after {
  margin-left: 0.5em;
  border-right: 1px solid #163257;
  border-bottom: 1px solid #163257;
  transform: rotate(-45deg) translateY(-50%);
}

.notice-content__wrap {
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
}
@media (max-width: 768.98px) {
  .notice-content__wrap {
    width: 100%;
    max-width: inherit;
  }
}

.notice-content {
  position: relative;
  padding-bottom: 50px;
  margin-right: 70px;
}
@media (max-width: 768.98px) {
  .notice-content {
    margin-right: 0;
  }
}
.notice-content:not(:first-child) {
  padding-top: 45px;
}
.notice-content:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}
.notice-content:last-of-type {
  padding-bottom: 100px;
}
@media (max-width: 768.98px) {
  .notice-content:last-of-type {
    padding-bottom: 50px;
  }
}
.notice-content__title {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 4rem;
  line-height: 1.6;
  color: #163257;
}
@media (max-width: 768.98px) {
  .notice-content__title {
    font-size: 2.5rem;
  }
}
.notice-content__main {
  font-size: 1.4rem;
}
.notice-content__main img {
  margin-bottom: 45px;
}
.notice-content__main p {
  line-height: 2.2;
}
.notice-content__link {
  display: block;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .notice-content__link:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .notice-content__link:hover {
  opacity: 0.7;
}

.notice-content__single-wrap {
  width: 100%;
}
@media (max-width: 768.98px) {
  .notice-content__single-wrap {
    margin-bottom: 30px;
  }
}

.notice-aside {
  flex-shrink: 0;
  width: 240px;
}
@media (max-width: 768.98px) {
  .notice-aside {
    width: 100%;
  }
}
.notice-aside__title {
  padding: 0 15px;
  font-size: 1.6rem;
  line-height: 2.5;
  color: #fff;
  background-color: #163257;
}
.notice-aside__lists {
  padding: 0 20px;
  margin-bottom: 50px;
  background: #fff;
}
@media (max-width: 768.98px) {
  .notice-aside__lists {
    margin-bottom: 20px;
  }
}
.notice-aside__lists li {
  font-size: 1.4rem;
  line-height: 1.35;
  letter-spacing: 0;
}
.notice-aside__lists li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.notice-aside__lists li a {
  display: block;
}

.notice-aside__news .notice-aside__list {
  padding: 30px 0;
}
@media (max-width: 768.98px) {
  .notice-aside__news .notice-aside__list {
    padding: 20px 0;
  }
}

.notice-aside__category .notice-aside__lists,
.notice-aside__archive .notice-aside__lists {
  padding: 15px 20px 5px;
}
.notice-aside__category li,
.notice-aside__archive li {
  padding: 13px 0;
}
.notice-aside__category li a,
.notice-aside__archive li a {
  display: block;
}

.notice-aside__archive {
  margin-bottom: 0;
}

.t-section1 .naname-heading {
  margin-bottom: 0;
}
.t-section1 .naname-heading__text {
  font-size: 24px;
}
@media (min-width: 960px) {
  .t-section1 .naname-heading__text {
    font-size: 30px !important;
  }
}
@media (min-width: 500px) {
  .t-section1 .naname-heading__text {
    font-size: calc(6 * ((100vw - 500px) / 460) + 24px);
  }
}
@media (min-width: 769px) {
  .t-section1__container {
    display: flex;
    flex-direction: row-reverse;
  }
}
.t-section1__content {
  max-width: 460px;
  margin: 50px auto 0;
}
@media (min-width: 769px) {
  .t-section1__content {
    flex-shrink: 0;
    width: 50%;
    padding-left: 20px;
    margin-top: -90px;
  }
}
.t-section1__img {
  width: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.t-section1__img::before {
  display: block;
  width: 100%;
  padding-top: 104.347826087%;
  content: "";
}
.t-section1__img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: initial;
  height: 100%;
}
.t-section1__caption {
  margin-top: 10px;
  font-size: 12px;
}
@media (min-width: 960px) {
  .t-section1__caption {
    font-size: 13.5px !important;
  }
}
@media (min-width: 500px) {
  .t-section1__caption {
    font-size: calc(1.5 * ((100vw - 500px) / 460) + 12px);
  }
}
.t-section1__text {
  max-width: 460px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1.6rem;
  line-height: 2.5;
  letter-spacing: 0;
}
@media (min-width: 769px) {
  .t-section1__text {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }
}
.t-section1__btn {
  max-width: 310px;
  margin: 0 auto;
  margin-top: 50px;
}
@media (min-width: 769px) {
  .t-section1__btn {
    margin-top: auto;
    margin-bottom: 20px;
  }
}
.t-section1__btn::after {
  background: transparent;
}

.t-section2 {
  margin-top: 70px;
}
@media (min-width: 769px) {
  .t-section2 {
    display: flex;
    padding: 0 40px;
    margin-top: 150px;
  }
}
.t-section2__video {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.t-section2__video::before {
  display: block;
  width: 100%;
  padding-top: 58.1196581197%;
  content: "";
}
.t-section2__text {
  max-width: 460px;
  margin: 25px auto 0;
  font-size: 1.6rem;
  line-height: 2.5;
}
@media (min-width: 769px) {
  .t-section2__text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.t-section3 {
  padding: 50px 10px 65px;
  margin-top: 65px;
  color: #fff;
  background: #807358;
}
@media (min-width: 769px) {
  .t-section3 {
    display: flex;
    padding: 45px 35px 45px 5px;
    margin: 65px 0 0;
  }
}
@media (min-width: 1080px) {
  .t-section3 {
    margin: 65px -40px 0;
  }
}
.t-section3__title {
  font-size: 26px;
  padding-bottom: 20px;
  border-bottom: 1px solid currentColor;
}
@media (min-width: 960px) {
  .t-section3__title {
    font-size: 40px !important;
  }
}
@media (min-width: 500px) {
  .t-section3__title {
    font-size: calc(14 * ((100vw - 500px) / 460) + 26px);
  }
}
@media (min-width: 769px) {
  .t-section3__title {
    width: 22%;
    text-align: center;
    border-right: 1px solid currentColor;
    border-bottom: none;
  }
}
.t-section3__container {
  padding-top: 35px;
  font-size: 1.6rem;
}
@media (min-width: 769px) {
  .t-section3__container {
    width: 78%;
    padding-top: 0;
    padding-left: 50px;
  }
}
.t-section3__container > a:not(:first-of-type) .t-section3__date {
  margin-top: 15px;
}
@media (min-width: 769px) {
  .t-section3__container > a:not(:first-of-type) .t-section3__date {
    margin-top: 0;
  }
}
.t-section3__container > a:not(:nth-last-of-type(2)) {
  border-bottom: 1px dotted currentColor;
}
.t-section3__link {
  display: block;
  padding: 0 5px;
}
@media (min-width: 769px) {
  .t-section3__link {
    display: flex;
    align-items: center;
    height: 60px;
  }
  .t-section3__link--height-half {
    height: 40px;
  }
}
.t-section3__link:last-of-type {
  margin-bottom: 20px;
}
.t-section3__date {
  display: block;
}
@media (min-width: 769px) {
  .t-section3__date {
    line-height: 1.6;
  }
}
.t-section3__heading {
  margin-bottom: 10px;
  line-height: 1.6;
}
@media (min-width: 769px) {
  .t-section3__heading {
    margin-bottom: 0;
    margin-left: 40px;
  }
}
.t-section3__btn {
  max-width: 240px;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .t-section3__btn {
    max-width: 310px;
  }
}

.t-section4 {
  margin-top: 70px;
}
@media (min-width: 769px) {
  .t-section4 {
    margin-top: 150px;
  }
}
.t-section4 .naname-heading__text {
  font-size: 27px;
}
@media (min-width: 960px) {
  .t-section4 .naname-heading__text {
    font-size: 40px !important;
  }
}
@media (min-width: 500px) {
  .t-section4 .naname-heading__text {
    font-size: calc(13 * ((100vw - 500px) / 460) + 27px);
  }
}
.t-section4__container {
  margin: -30px 0 0 -15px;
}
.t-section4__container > .row__gap {
  padding: 30px 0 0 15px;
}
.t-section4__item {
  flex: 0 0 100%;
  width: 100%;
  font-size: 1.6rem;
}
@media (min-width: 500px) {
  .t-section4__item {
    flex: 0 0 50%;
    width: 50%;
  }
}
@media (min-width: 769px) {
  .t-section4__item {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }
}
.t-section4__link {
  display: block;
  transition: opacity 0.7s;
}
@media (hover: hover) {
  .t-section4__link:hover {
    opacity: 0.7;
  }
}
_:-ms-fullscreen:hover, :root .t-section4__link:hover {
  opacity: 0.7;
}

.t-section4__img {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.t-section4__img::before {
  display: block;
  width: 100%;
  padding-top: 55.9171597633%;
  content: "";
}
.t-section4__img > img {
  top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.t-section4__text {
  display: block;
  margin-top: 15px;
  text-align: center;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mt-8 {
  margin-top: 4rem;
}

.mb-8 {
  margin-bottom: 4rem;
}

.mt-9 {
  margin-top: 4.5rem;
}

.mb-9 {
  margin-bottom: 4.5rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.mt-11 {
  margin-top: 5.5rem;
}

.mb-11 {
  margin-bottom: 5.5rem;
}

.mt-12 {
  margin-top: 6rem;
}

.mb-12 {
  margin-bottom: 6rem;
}

.mt-13 {
  margin-top: 6.5rem;
}

.mb-13 {
  margin-bottom: 6.5rem;
}

.mt-14 {
  margin-top: 7rem;
}

.mb-14 {
  margin-bottom: 7rem;
}

.mt-15 {
  margin-top: 7.5rem;
}

.mb-15 {
  margin-bottom: 7.5rem;
}

.mt-16 {
  margin-top: 8rem;
}

.mb-16 {
  margin-bottom: 8rem;
}

.mt-17 {
  margin-top: 8.5rem;
}

.mb-17 {
  margin-bottom: 8.5rem;
}

.mt-18 {
  margin-top: 9rem;
}

.mb-18 {
  margin-bottom: 9rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

.pt-7 {
  padding-top: 3.5rem;
}

.pb-7 {
  padding-bottom: 3.5rem;
}

.pt-8 {
  padding-top: 4rem;
}

.pb-8 {
  padding-bottom: 4rem;
}

.pt-9 {
  padding-top: 4.5rem;
}

.pb-9 {
  padding-bottom: 4.5rem;
}

.pt-10 {
  padding-top: 5rem;
}

.pb-10 {
  padding-bottom: 5rem;
}

.pt-11 {
  padding-top: 5.5rem;
}

.pb-11 {
  padding-bottom: 5.5rem;
}

.pt-12 {
  padding-top: 6rem;
}

.pb-12 {
  padding-bottom: 6rem;
}

.pt-13 {
  padding-top: 6.5rem;
}

.pb-13 {
  padding-bottom: 6.5rem;
}

.pt-14 {
  padding-top: 7rem;
}

.pb-14 {
  padding-bottom: 7rem;
}

.pt-15 {
  padding-top: 7.5rem;
}

.pb-15 {
  padding-bottom: 7.5rem;
}

.pt-16 {
  padding-top: 8rem;
}

.pb-16 {
  padding-bottom: 8rem;
}

.pt-17 {
  padding-top: 8.5rem;
}

.pb-17 {
  padding-bottom: 8.5rem;
}

.pt-18 {
  padding-top: 9rem;
}

.pb-18 {
  padding-bottom: 9rem;
}

@media (min-width: 500px) {
  .pt-xs-0 {
    padding-top: 0rem;
  }

  .pb-xs-0 {
    padding-bottom: 0rem;
  }

  .pt-xs-1 {
    padding-top: 0.5rem;
  }

  .pb-xs-1 {
    padding-bottom: 0.5rem;
  }

  .pt-xs-2 {
    padding-top: 1rem;
  }

  .pb-xs-2 {
    padding-bottom: 1rem;
  }

  .pt-xs-3 {
    padding-top: 1.5rem;
  }

  .pb-xs-3 {
    padding-bottom: 1.5rem;
  }

  .pt-xs-4 {
    padding-top: 2rem;
  }

  .pb-xs-4 {
    padding-bottom: 2rem;
  }

  .pt-xs-5 {
    padding-top: 2.5rem;
  }

  .pb-xs-5 {
    padding-bottom: 2.5rem;
  }

  .pt-xs-6 {
    padding-top: 3rem;
  }

  .pb-xs-6 {
    padding-bottom: 3rem;
  }

  .pt-xs-7 {
    padding-top: 3.5rem;
  }

  .pb-xs-7 {
    padding-bottom: 3.5rem;
  }

  .pt-xs-8 {
    padding-top: 4rem;
  }

  .pb-xs-8 {
    padding-bottom: 4rem;
  }

  .pt-xs-9 {
    padding-top: 4.5rem;
  }

  .pb-xs-9 {
    padding-bottom: 4.5rem;
  }

  .pt-xs-10 {
    padding-top: 5rem;
  }

  .pb-xs-10 {
    padding-bottom: 5rem;
  }

  .pt-xs-11 {
    padding-top: 5.5rem;
  }

  .pb-xs-11 {
    padding-bottom: 5.5rem;
  }

  .pt-xs-12 {
    padding-top: 6rem;
  }

  .pb-xs-12 {
    padding-bottom: 6rem;
  }

  .pt-xs-13 {
    padding-top: 6.5rem;
  }

  .pb-xs-13 {
    padding-bottom: 6.5rem;
  }

  .pt-xs-14 {
    padding-top: 7rem;
  }

  .pb-xs-14 {
    padding-bottom: 7rem;
  }

  .pt-xs-15 {
    padding-top: 7.5rem;
  }

  .pb-xs-15 {
    padding-bottom: 7.5rem;
  }

  .pt-xs-16 {
    padding-top: 8rem;
  }

  .pb-xs-16 {
    padding-bottom: 8rem;
  }

  .pt-xs-17 {
    padding-top: 8.5rem;
  }

  .pb-xs-17 {
    padding-bottom: 8.5rem;
  }

  .pt-xs-18 {
    padding-top: 9rem;
  }

  .pb-xs-18 {
    padding-bottom: 9rem;
  }
}
@media (min-width: 769px) {
  .pt-sm-0 {
    padding-top: 0rem;
  }

  .pb-sm-0 {
    padding-bottom: 0rem;
  }

  .pt-sm-1 {
    padding-top: 0.5rem;
  }

  .pb-sm-1 {
    padding-bottom: 0.5rem;
  }

  .pt-sm-2 {
    padding-top: 1rem;
  }

  .pb-sm-2 {
    padding-bottom: 1rem;
  }

  .pt-sm-3 {
    padding-top: 1.5rem;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem;
  }

  .pt-sm-4 {
    padding-top: 2rem;
  }

  .pb-sm-4 {
    padding-bottom: 2rem;
  }

  .pt-sm-5 {
    padding-top: 2.5rem;
  }

  .pb-sm-5 {
    padding-bottom: 2.5rem;
  }

  .pt-sm-6 {
    padding-top: 3rem;
  }

  .pb-sm-6 {
    padding-bottom: 3rem;
  }

  .pt-sm-7 {
    padding-top: 3.5rem;
  }

  .pb-sm-7 {
    padding-bottom: 3.5rem;
  }

  .pt-sm-8 {
    padding-top: 4rem;
  }

  .pb-sm-8 {
    padding-bottom: 4rem;
  }

  .pt-sm-9 {
    padding-top: 4.5rem;
  }

  .pb-sm-9 {
    padding-bottom: 4.5rem;
  }

  .pt-sm-10 {
    padding-top: 5rem;
  }

  .pb-sm-10 {
    padding-bottom: 5rem;
  }

  .pt-sm-11 {
    padding-top: 5.5rem;
  }

  .pb-sm-11 {
    padding-bottom: 5.5rem;
  }

  .pt-sm-12 {
    padding-top: 6rem;
  }

  .pb-sm-12 {
    padding-bottom: 6rem;
  }

  .pt-sm-13 {
    padding-top: 6.5rem;
  }

  .pb-sm-13 {
    padding-bottom: 6.5rem;
  }

  .pt-sm-14 {
    padding-top: 7rem;
  }

  .pb-sm-14 {
    padding-bottom: 7rem;
  }

  .pt-sm-15 {
    padding-top: 7.5rem;
  }

  .pb-sm-15 {
    padding-bottom: 7.5rem;
  }

  .pt-sm-16 {
    padding-top: 8rem;
  }

  .pb-sm-16 {
    padding-bottom: 8rem;
  }

  .pt-sm-17 {
    padding-top: 8.5rem;
  }

  .pb-sm-17 {
    padding-bottom: 8.5rem;
  }

  .pt-sm-18 {
    padding-top: 9rem;
  }

  .pb-sm-18 {
    padding-bottom: 9rem;
  }
}
@media (min-width: 960px) {
  .pt-md-0 {
    padding-top: 0rem;
  }

  .pb-md-0 {
    padding-bottom: 0rem;
  }

  .pt-md-1 {
    padding-top: 0.5rem;
  }

  .pb-md-1 {
    padding-bottom: 0.5rem;
  }

  .pt-md-2 {
    padding-top: 1rem;
  }

  .pb-md-2 {
    padding-bottom: 1rem;
  }

  .pt-md-3 {
    padding-top: 1.5rem;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem;
  }

  .pt-md-4 {
    padding-top: 2rem;
  }

  .pb-md-4 {
    padding-bottom: 2rem;
  }

  .pt-md-5 {
    padding-top: 2.5rem;
  }

  .pb-md-5 {
    padding-bottom: 2.5rem;
  }

  .pt-md-6 {
    padding-top: 3rem;
  }

  .pb-md-6 {
    padding-bottom: 3rem;
  }

  .pt-md-7 {
    padding-top: 3.5rem;
  }

  .pb-md-7 {
    padding-bottom: 3.5rem;
  }

  .pt-md-8 {
    padding-top: 4rem;
  }

  .pb-md-8 {
    padding-bottom: 4rem;
  }

  .pt-md-9 {
    padding-top: 4.5rem;
  }

  .pb-md-9 {
    padding-bottom: 4.5rem;
  }

  .pt-md-10 {
    padding-top: 5rem;
  }

  .pb-md-10 {
    padding-bottom: 5rem;
  }

  .pt-md-11 {
    padding-top: 5.5rem;
  }

  .pb-md-11 {
    padding-bottom: 5.5rem;
  }

  .pt-md-12 {
    padding-top: 6rem;
  }

  .pb-md-12 {
    padding-bottom: 6rem;
  }

  .pt-md-13 {
    padding-top: 6.5rem;
  }

  .pb-md-13 {
    padding-bottom: 6.5rem;
  }

  .pt-md-14 {
    padding-top: 7rem;
  }

  .pb-md-14 {
    padding-bottom: 7rem;
  }

  .pt-md-15 {
    padding-top: 7.5rem;
  }

  .pb-md-15 {
    padding-bottom: 7.5rem;
  }

  .pt-md-16 {
    padding-top: 8rem;
  }

  .pb-md-16 {
    padding-bottom: 8rem;
  }

  .pt-md-17 {
    padding-top: 8.5rem;
  }

  .pb-md-17 {
    padding-bottom: 8.5rem;
  }

  .pt-md-18 {
    padding-top: 9rem;
  }

  .pb-md-18 {
    padding-bottom: 9rem;
  }
}
@media (min-width: 1200px) {
  .pt-lg-0 {
    padding-top: 0rem;
  }

  .pb-lg-0 {
    padding-bottom: 0rem;
  }

  .pt-lg-1 {
    padding-top: 0.5rem;
  }

  .pb-lg-1 {
    padding-bottom: 0.5rem;
  }

  .pt-lg-2 {
    padding-top: 1rem;
  }

  .pb-lg-2 {
    padding-bottom: 1rem;
  }

  .pt-lg-3 {
    padding-top: 1.5rem;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem;
  }

  .pt-lg-4 {
    padding-top: 2rem;
  }

  .pb-lg-4 {
    padding-bottom: 2rem;
  }

  .pt-lg-5 {
    padding-top: 2.5rem;
  }

  .pb-lg-5 {
    padding-bottom: 2.5rem;
  }

  .pt-lg-6 {
    padding-top: 3rem;
  }

  .pb-lg-6 {
    padding-bottom: 3rem;
  }

  .pt-lg-7 {
    padding-top: 3.5rem;
  }

  .pb-lg-7 {
    padding-bottom: 3.5rem;
  }

  .pt-lg-8 {
    padding-top: 4rem;
  }

  .pb-lg-8 {
    padding-bottom: 4rem;
  }

  .pt-lg-9 {
    padding-top: 4.5rem;
  }

  .pb-lg-9 {
    padding-bottom: 4.5rem;
  }

  .pt-lg-10 {
    padding-top: 5rem;
  }

  .pb-lg-10 {
    padding-bottom: 5rem;
  }

  .pt-lg-11 {
    padding-top: 5.5rem;
  }

  .pb-lg-11 {
    padding-bottom: 5.5rem;
  }

  .pt-lg-12 {
    padding-top: 6rem;
  }

  .pb-lg-12 {
    padding-bottom: 6rem;
  }

  .pt-lg-13 {
    padding-top: 6.5rem;
  }

  .pb-lg-13 {
    padding-bottom: 6.5rem;
  }

  .pt-lg-14 {
    padding-top: 7rem;
  }

  .pb-lg-14 {
    padding-bottom: 7rem;
  }

  .pt-lg-15 {
    padding-top: 7.5rem;
  }

  .pb-lg-15 {
    padding-bottom: 7.5rem;
  }

  .pt-lg-16 {
    padding-top: 8rem;
  }

  .pb-lg-16 {
    padding-bottom: 8rem;
  }

  .pt-lg-17 {
    padding-top: 8.5rem;
  }

  .pb-lg-17 {
    padding-bottom: 8.5rem;
  }

  .pt-lg-18 {
    padding-top: 9rem;
  }

  .pb-lg-18 {
    padding-bottom: 9rem;
  }
}
.svg-notice, .svg-home, .svg-footer, .svg-disciple, .svg-contact, .svg-anecdote, .svg-about {
  background: url("../img/sprites/sprite.view.svg") no-repeat;
}

.svg-about {
  background-position: 99.8486106363% 0;
}

.svg-about-dims {
  width: 107.23px;
  height: 64.32px;
}

.svg-anecdote {
  background-position: 32.3378223374% 98.0810810811%;
}

.svg-anecdote-dims {
  width: 76.61px;
  height: 80px;
}

.svg-contact {
  background-position: 97.3526330826% 27.2207880147%;
}

.svg-contact-dims {
  width: 101.98px;
  height: 65.71px;
}

.svg-disciple {
  background-position: 0 99.9357444465%;
}

.svg-disciple-dims {
  width: 76.12px;
  height: 84.12px;
}

.svg-footer {
  background-position: 0 0;
}

.svg-footer-dims {
  width: 204.46px;
  height: 217.74px;
}

.svg-home {
  background-position: 64.3941310397% 95.8447046395%;
}

.svg-home-dims {
  width: 74.82px;
  height: 74.82px;
}

.svg-notice {
  background-position: 95.4528478058% 55.9943157351%;
}

.svg-notice-dims {
  width: 97.8px;
  height: 69.78px;
}

.hidden {
  visibility: hidden !important;
}

.none {
  display: none !important;
}

@media (max-width: 499.98px) {
  .sp-none {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .pc-none {
    display: none !important;
  }
}
@media (max-width: 768.98px) {
  .tb-none {
    display: none !important;
  }
}
_:-ms-fullscreen, :root .ie-none {
  display: none !important;
}

.max30 {
  font-size: 16px;
}
@media (min-width: 960px) {
  .max30 {
    font-size: 30px !important;
  }
}
@media (min-width: 500px) {
  .max30 {
    font-size: calc(14 * ((100vw - 500px) / 460) + 16px);
  }
}
@media (max-width: 768.98px) {
  .max30 {
    line-height: 1.25;
  }
}

.max40 {
  font-size: 29px;
}
@media (min-width: 960px) {
  .max40 {
    font-size: 40px !important;
  }
}
@media (min-width: 500px) {
  .max40 {
    font-size: calc(11 * ((100vw - 500px) / 460) + 29px);
  }
}

.color-red {
  color: red;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Style
==================== */
/* Overwrites
==================== */