.disciple{
  dt{
    float: left;
    clear: both;
    font-size: 16px;
    @include media(sm,max){
      float: none;
      margin-bottom: 20px;
      clear: none;
    }
  }
  dd{
    padding-left: 15rem;
    margin-bottom: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: line-height(30,14);
    text-indent: 0;
    @include media(sm,max){
      padding: 0 10px;
    }
    p{
      line-height: line-height(30,14);
    }
  }
  .keiko-tel_form{
    padding: 30px;
    background-color: color(white);
    border: 5px solid #807358;
    &-btn{
      color: color(brown);
      @include hover;
    }
    .tel-link{
      margin-top: 25px;
      &_num{
        @include hover;
        font-size: 25px;
        line-height: line-height(25,25);
      }
    }
  }
}
.kengaku-form{
  @include media(sm,max){
    display: flex;
    justify-content: center;
    &_btn {
      font-size: 1vw;
    }
  }
}
.list-indent{
  position: relative;
  padding-left: 2rem;
  &::before{
    position: absolute;
    left: 0;
    content: '・';
  }
}

.underline {
  text-decoration: underline;
}
