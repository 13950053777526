input {
  width: 100%;
  max-width: 290px;
  height: 40px;
  border: 1px solid #ccc;
}
textarea {
  width: 100%;
  max-width: 340px;
  height: 120px;
  border: 1px solid #ccc;
}

.form {
  &__name {}
  &__mail {}
  &__tel {}
  &__textareta {}
  &__btn {
    width: 100%;
    max-width: 305px;
    height: 60px;
    margin: 0 auto;
    margin-top: 200px;
    font-size: 2rem;
    @include media(sm) {
      max-width: 670px;
    }
    @include media(xs){
      margin-top: 100px;
    }
  }
  .wpcf7c-btn-back {
    margin-top: 30px;
  }
}
