.row {
  display: flex;
  flex-wrap: wrap;
  &__gap {
    min-width: 0;

  }
}

@mixin gap($top: 0, $left: 0) {
  margin: px($top,-1) 0 0 px($left,-1);
  > .row__gap {
    padding: px($top) 0 0 px($left);
  }
}


@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 percentage(1 / $i);
    max-width: percentage(1 / $i);
  }
}
@each $bp, $query in $breakpoints {
  @include media($bp) {
    @for $i from 1 through 12 {
      .col-#{$bp}-#{$i} {
        flex: 0 0 percentage(1 / $i);
        max-width: percentage(1 / $i);
      }
    }
  }
}

// @for $i from 0 through 6 {
//   @for $j from 0 through 6 {
//     .gap-#{$i}-#{$j} {
//       margin: px($i,-1) 0 0 px($j,-1);
//       > .row__gap {
//         padding: px($i) 0 0 px($j);
//       }
//     }
//   }
// }

// @each $bp, $query in $breakpoints {
//   @include media($bp) {
//     @for $i from 0 through 6 {
//       @for $j from 0 through 6 {
//         .gap-#{$bp}-#{$i}-#{$j} {
//           margin: px($i,-5) 0 0 px($j,-5);
//           > .row__gap {
//             padding: px($i,5) 0 0 px($j,5);
//           }
//         }
//       }
//     }
//   }
// }
