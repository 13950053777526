body {
  font-family: $font-base;
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100vh;
    content: '';
    background: url('../img/home/hero_text@2x.png') no-repeat bottom 25px right 75px,url('../img/home/hero_pc.png') no-repeat top left 47%;
    background-size: 196px 58px,cover;
    transform: translateZ(0);
    @include media(sm,max) {
      background: url('../img/home/hero_text@2x.png') no-repeat bottom 15px right 15px,url('../img/home/hero_sp.png') no-repeat center center;
      background-size: 196px 58px,cover;
    }
  }
  &.top {
    background: color(brown,300);
    main  {
      max-width: 1225px;
      padding: 75px 0 100px;
      margin: 0 auto;
      background: url('../img/home/bg.png') no-repeat,url('../img/home/bg-repeat.png') repeat-y;
      background-size: 100% auto;
      box-shadow: 0 7px 11px color(gray);
      @media (max-width: 1265px) {
        margin: 0 20px;
      }
      @media (min-width: 1200px) {
        background-size: cover;
      }
    }
  }
  &:not(.top) .bg_color {
    background: url('../img/common/bg.png') repeat-y;
    background-position: top 450px center;
    background-size: 100% auto;
  }
}

.wrapper {
  max-width: $inner-width + $wrapper-LRgap * 2;
  padding: 0 $wrapper-LRgap;
  margin: 0 auto;
  &.top {
    padding: 0 15px;
  }
}

.top_bg_color {
  background-color: #c6c4be;
}