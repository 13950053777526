
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__img {
    @include pt(16,9);
    > img {
      @include position(xy);
    }
  }
  &__btn {
    margin-top: auto;
  }
}