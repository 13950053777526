// @font-face {
//   font-family: 'name';
//   font-style: normal;
//   font-weight: normal;
//   src:
//   local('name'),
//   url('url.woff') format('woff');
//   font-display: swap;
// }

$font-base: 'ヒラギノ明朝 Pro W3',
  'Hiragino Mincho ProN',
  'HG明朝E',
  'ＭＳ Ｐ明朝',
  'ＭＳ 明朝',
  serif;


$font-gosick: 'ヒラギノ角ゴ Pro W3',
  'HiraKakuProN-W4',
  'メイリオ',
  meiryo,
  'ＭＳ Ｐゴシック',
  sans-serif;