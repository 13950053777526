.ico {
  &-svg {
    display: block;
    > use {
      fill: color(blue);
      transition: fill .7s;
    }
  }
  &-home {
    width: 55px;
    height: 55px;
  }
  &-notice {
    width: 78px;
    height: 50px;
  }
  &-about {
    width: 87px;
    height: 44px;
  }
  &-anecdote {
    width: 57px;
    height: 60px;
  }
  &-disciple {
    width: 56px;
    height: 64px;
  }
  &-contact {
    width: 82px;
    height: 46px;
  }
  &-footer {
    width: 110px;
    height: 118px;
    @include media(sm) {
      width: 185px;
      height: 200px;
    }
    > use {
      fill: color(white);
    }
  }
}

// %default {
//   display: block;
//   content: '';
//   background-repeat: no-repeat;
//   background-size: contain;
// }

// a[href$=".pdf"]::after {
//   @extend %default;
//   display: inline-block;
//   background-image: url(../img/common/icons/);
// }

// //絶対パスで記述されている内部リンクのtarget="_blank"を除外
// a:not([href="instagram.com/"])[target="_blank"]::after {
//   @extend %default;
//   display: inline-block;
//   background-image: url(../img/common/icons/);
// }

// a[href*="instagram.com/"]{}
// a[href*="facebook.com/"]{}
// a[href*="youtube.com/"]{}
// a[href*="twitter.com/"]{}