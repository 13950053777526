.contact {
  font-size: 1.4rem;
  &__title {
    margin-top: 30px;
    margin-bottom: 45px;
    > span {
      @include fz(22,39);
    }
  }
  &__text {

  }
  &__list {
    padding-bottom: 40px;
    line-height: 1.6;
    border-bottom: 2px solid color(brown);
  }
  &__item {
    > a {
      color: color(blue);
      text-decoration: underline;
    }
  }
}

.contact-form {
  margin-top: 90px;
  &__head {
    margin-bottom: 30px;
  }
  &__container {
  }
  &__title {
    @include fz(16,14);
    height: 30px;
    @include flex(y);
    .color-red {
      margin-left: 10px;
    }
    @include media(xs){
      float: left;
    }
  }
  &__text {
    margin-bottom: 10px;
    @include media(xs){
      padding-left: 125px;
      margin-bottom: 30px;
    }
  }
  .form__textarea{
    @include media(xs){
      width: 100%;
      max-width: 100%;
    }
  }
}
input, textarea {
  padding: .3em .5em;
}