
// 斜めの見出
.naname-heading{
  &-outer {
    @include overflow;
  }
  position: relative;
  justify-content: flex-end;
  width: calc(100% - 130px);
  height: 65px;
  margin: 0 0 60px;
  color: color(white);
  background-color: color(blue);
  @include flex(y);
  // tb以上のサイズ設定
  @include media (sm){
    width: calc(50% - 130px);
    height: 90px;
    margin-right: calc(50% + 20px);
  }
  &__text {
    display: block;
    width: 100%;
    @include fz (29,40);
    padding-left: 20px;
    @include media(sm) {
      max-width: 430px;
    }
  }
  // 三角形の設定
  &::after{
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    content: '';
    border-color:  color(blue) transparent transparent transparent;
    border-style: solid;
    border-width: 65px 50px 0 0;
    // tb以上のサイズ設定
    @include media (sm){
      border-width: 90px 110px 0 0;
    }
  }
}
