.notice{
  font-family: $font-gosick;

  .wrapper{
    display: flex;
    padding-top: 60px;

    @include media(sm, max){
      flex-direction: column;
    }
  }

  &-label{
    display: inline-block;
    padding: 0 1em;
    margin-bottom: 10px;
    font-family: $font-gosick;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #fff;
    background-color: #807358;
  }

  &-date{
    display: inline-block;
    font-family: $font-gosick;
    font-size: 1.2rem;
    color: #78797c;
  }

  &-btns{
    display: flex;
    justify-content: center;
  }

  &-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 60px;
    font-size: 1.4rem;
    border: 1px solid color(blue);
    @include hover;
    &::before,
    &::after{
      position: relative;
      bottom: -2px;
      display: inline-block;
      width: 6px;
      height: 6px;
      content: '';
    }


    &.prev{
      margin-right: 10px;
      &::before{
        margin-right: .5em;
        border-top: 1px solid color(blue);
        border-left: 1px solid color(blue);
        transform: rotate(-45deg) translateY(-50%);
      }
    }

    &.next{
      &::after{
        margin-left: .5em;
        border-right: 1px solid color(blue);
        border-bottom: 1px solid color(blue);
        transform: rotate(-45deg) translateY(-50%);
      }
    }
  }
}

.notice-content__wrap{
  width: 100%;
  max-width: 650px;
  margin-bottom: 30px;
  @include media(sm, max){
    width: 100%;
    max-width: inherit;
  }
}

.notice-content{
  position: relative;
  padding-bottom: 50px;
  margin-right: 70px;
  @include media(sm, max){
    margin-right: 0;
  }

  &:not(:first-child){
    padding-top: 45px;
  }

  &:not(:last-of-type){
    border-bottom: 1px solid #ddd;
  }

  &:last-of-type{
    padding-bottom: 100px;
    @include media(sm, max){
      padding-bottom: 50px;
    }
  }

  &__title{
    font-family: $font-base;
    font-size: 4rem;
    line-height: 1.6;
    color: color(blue);
    @include media(sm, max){
      font-size: 2.5rem;
    }
  }

  // &__img{
  //   margin-bottom: 45px;
  // }

  &__main{
    font-size: 1.4rem;
    img{
      margin-bottom: 45px;
    }

    p{
      line-height: 2.2;
    }
  }

  &__link{
    display: block;
    @include hover;
  }

  &__single-wrap{
    width: 100%;
    @include media(sm, max){
      margin-bottom: 30px;
    }
  }
}

.notice-aside{
  flex-shrink: 0;
  width: 240px;
  @include media(sm, max){
    width: 100%;
  }

  &__title{
    padding: 0 15px;
    font-size: 1.6rem;
    line-height: 2.5;
    color: #fff;
    background-color: color(blue);
  }

  &__lists{
    padding: 0 20px;
    margin-bottom: 50px;
    background: #fff;
    @include media(sm, max){
      margin-bottom: 20px;
    }
    li{
      font-size: 1.4rem;
      line-height: 1.35;
      letter-spacing: 0;
      &:not(:last-child){
        border-bottom: 1px solid #ddd;
      }
      a{
        display: block;
      }
    }
  }
}

.notice-aside__news{
  .notice-aside__list{
    padding: 30px 0;
    @include media(sm, max){
      padding: 20px 0;
    }
  }
}

.notice-aside__category,
.notice-aside__archive{
  .notice-aside{
    &__lists{
      padding: 15px 20px 5px;
    }
  }
  li{
    padding: 13px 0;
    a{
      display: block;
    }
  }
}

.notice-aside__archive{
  margin-bottom: 0;
}