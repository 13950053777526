
// // anecdote
// .about, .anecdote {
//   .page-container_text {
//     font-size: px(14 * 1.2);
//     line-height: line-height(30, 14);
//     text-align: justify;
//   }
// }
// .anecdote_text-box{
//   display: flex;
//   flex-direction: column;
//   @include media(sm){
//     flex-direction: unset;
//     flex-wrap: nowrap;
//     justify-content: space-between;
//   }
//   &-inner {
//     width: 100%;
//     margin-bottom: 20px;
//     @include media(sm){
//       width: 65%;
//     }
//   }
//   > picture{
//     width: 100%;
//     @include media(sm){
//       width: 30%;
//     }
//   }
//   &--reverse{
//     display: flex;
//     flex-direction: column-reverse;
//     @include media(sm){
//       flex-direction: unset;
//       flex-wrap: nowrap;
//       justify-content: space-between;
//     }
//     > p {
//       width: 100%;
//       margin-bottom: 20px;
//       @include media(sm){
//         width: 65%;
//       }
//     }
//     > figure{
//       width: 100%;
//       @include media(sm){
//         width: 30%;
//       }
//     }
//   }
// }

.anecdote {
  .decoration {
    position: relative;
    margin-top: 70px;
    &::before {
      position: absolute;
      top: (-19px - 30px);
      left: 50%;
      display: block;
      width: 39px;
      height: 30px;
      content: '';
      background-image: url(../img/anecdote/decoration01.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translateX(-50%);
    }
    @include media(sm){
      margin-top: 90px;
      &::before {
        top: (-18px - 53px);
        width: 68px;
        height: 53px;
      }
    }
  }

  .anecdote-explanation {
    padding-bottom: 35px;
    font-size: 16px;
  }
  .anecdote_pic-box {
    .anecdote-row {
      display: flex;
      flex-wrap: wrap;

      @include media(sm){
        margin-top: -20px;
        margin-left: -20px;
      }
    }
  }

  .anecdote-col {
    margin-top: 20px;

    @include media(sm){
      width: calc(50% - 20px);
      margin-top: 20px;
      margin-left: 20px;
    }

    @include media(md){
      width: calc(33.3333% - 20px);
      margin-top: 20px;
      margin-left: 20px;
    }

    :hover {
      color: rgba(255, 255, 255, .54);
    }
  }

  .anecdote_pic-margin {
    margin-top: 15px;
    @include media(sm){
      margin-top: 0;
    }
  }

  .modal__outer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: none;
    width: 100%;
    height: 100%;
    background: color(gray,300);

    &.is-open {
      display: block;
    }
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000;
    // display: none;
    width: calc(100% - 30px);
    max-width: 1140px;
    padding: 55px 27px 20px;
    margin: 0 auto;
    background: rgba(#163257, .96);
    transform: translate(-50%,-50%);
    overflow: auto;
    height: 100vh;

    @include media(md) {
      width: 100%;
      padding: 50px 100px 40px;
    }

    &__content {
      position: relative;
      display: none;
      width: 100%;
      max-width: 1140px;

      &.is-active {
        display: flex;
        flex-wrap: wrap;
      }


    }
    &__img {
      width: 100%;

      > img {
        max-height: 600px;
        object-fit: contain;
      }
    }
    &__inner {
      padding-top: 20px;
      padding-left: 0;
      color: white;

      // @include media(md){
        // padding-top: 60px;
        // padding-left: 50px;
      // }

    }

    &__title {
      font-size: 18px;
      font-weight: 600;
    }

    &__text {
      padding-top: 20px;
      font-size: 14px;

      // @include media(md){
        // padding-top: 55px;
      // }
    }
  }
  .js-modal-open {
    cursor: pointer;
    @include hover;
  }

  .modal-close-button {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 60px;
    padding-top: 40px;
    font-size: 1.2rem;
    color: white;

    @include media(md) {
      top: 50px;
      right: 30px;
      padding-top: 40px;
    }

    &::before,
    &::after {
      position: absolute;
      top: 30px;
      left: 10px;
      display: block;
      width: 30px;
      height: 3px;
      content: '';
      background: white;
      transform: rotate(-45deg);

      @include media(md) {
        top: 20px;
        left: -1px;
        width: 50px;
      }
    }
    &::after {
      transform: rotate(-135deg);
    }
  }
}
