.gnav {
  $this: &;
  position: relative;
  height: 87px;
  background: color(gray,400);
  @include flex;
  @include media(sm) {
    height: 185px;
  }
  &.top {
    height: 140px;
    @include media(sm) {
      height: 185px;
    }
  }
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background: color(brown);
    transition: transform .8s;
    transform: translateX(100%);
    @include media(sm) {
      position: static;
      height: auto;
      background: transparent;
      transition: none;
      transform: translate(0);
    }
  }
  &.is-open {
    .gnav__container {
      transform: translateX(0);
    }
  }
  &__logo {
    width: 100%;
    max-width: 160px;
    margin: 10px auto 0;
    box-shadow: 0 7px 7px color(gray);
  }
  &__list {
    width: 100%;
    margin-top: 30px;
    @include media(sm) {
      max-width: 1200px;
      margin: 0 auto;
      @include flex;
    }
  }
  &__item {
    $this: &;
    position: relative;
    @include media(sm) {
      flex: 0 0 percentage(1/8);
      max-width: percentage(1/8);
    }
    &.is-current .ico-svg > use {
      fill: color(brown);
    }
    &--offset {
      @extend #{$this};
      @include media(sm) {
        margin-left: 25%;
      }
    }
    &:not(:last-of-type) {
      &::before {
        bottom: -.5px;
        display: block;
        width: 25px;
        height: 1px;
        content: '';
        background: color(white);
        @include position(x);
        @include media(sm) {
          display: none
        }
      }
    }
  }
  &__link {
    height: 80px;
    @include flex;
    @include media(sm) {
      flex-direction: column;
      height: 100px;
    }
    .ico {
      &-svg {
        margin: 0 auto;
        @include media(sm,max) {
          display: none;
        }
      }
      &-home {
        margin-top: 5px;
      }
      &-notice {
        margin-top: 8px;
      }
      &-about {
        margin-top: 15px;
      }
      &-anecdote {
        margin-top: 2px;
      }
      &-disciple {
      }
      &-contact {
        margin-top: 11px;
      }
    }
    &:hover {
      .ico-svg > use {
        fill: color(brown);
      }
    }
  }
  &__title {
    display: block;
    font-size: 2rem;
    color: color(white);
    text-align: center;
    @include media(sm) {
      margin-top: auto;
      font-size: 1.4rem;
      color: color(black);
    }
    > br {
      display: none;
      @include media(sm) {
        display: inline;
      }
      @include media(md) {
        display: none;
      }
    }
  }
}

.logo {
  top: 25px;
  z-index: 1;
  width: 200px;
  box-shadow: 0 7px 7px color(gray);
  @include position;

  @include media(sm) {

    width: 25%;
    max-width: 280px;

  }
}

.gnav-inner {
  width: 100%;
  max-width: 1200px;
}