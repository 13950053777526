.footer {
  position: relative;
  justify-content: flex-start;
  max-width: 1165px;
  height: 150px;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 115px;
  color: color(blue);
  @include flex(y);
  @include media(sm) {
    height: 215px;
  }
  &.top {
    margin-top: 0;
  }
  &:not(.top)::before {
    top: 0;
    display: block;
    width: calc(100% - 40px);
    height: 1px;
    content: '';
    background: color(blue);
    @include position(x);
  }
  &__container {
    @include flex(x);
    width: 100%;
    max-width: 1000px;
    @include media(sm) {
      justify-content: space-between;
    }
  }
  .ico-footer {
    position: absolute;
    right: 5px;
    bottom: 5px;
    @include media(lg) {
      right: -75px;
    }
  }
}

.address {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  &__title {
    font-family: $font-gosick;
    font-size: 1.9rem;
    line-height: 1;
    text-align: center;
    @include media(sm) {
      font-size: 2.2rem;
    }
    > span {
      font-family: $font-base;
      font-size: 2.8rem;
      letter-spacing: letter(60);
      @include media(sm) {
        font-size: 3rem;
      }
    }
  }
  &__text {
    margin-top: 15px;
    @include fz(14,16);
    line-height: 1;
    text-align: center;
    > a {
      margin-left: 15px;
      letter-spacing: 0;
    }
  }
  &__copy {
    display: block;
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
  }
}

.footer-nav {
  position: relative;
  z-index: 1;
  width: 100%;
  &__container {
    @include flex;
    width: 100%;
    max-width: 610px;
    margin-left: auto;
  }
  &__item {
    flex: 0 0 percentage(3/17);
    max-width: percentage(3/17);
    @include fz(13.5,16,sm);
    text-align: center;
    $this: &;
    &--big {
      @extend #{$this};
      flex: 0 0 percentage(5/17);
      max-width: percentage(5/17);
    }
  }
  &__link {
    @include flex;
    height: 32px;
    @include hover;
    > br {
      display: none;
      @include media(sm) {
        display: inline;
      }
      @include media(md) {
        display: none;
      }
    }
  }
  &__btn.btnBR {
    width: 50%;
    max-width: 340px;
    margin-top: 40px;
    margin-left: auto;
    color: color(white);
    transition: background-color .7s;
    &:hover {
      background: lighten(color(brown), 10%);
      opacity: 1;
    }
  }
}