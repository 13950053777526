@for $i from 1 through 18 {
  .pt-#{$i} {
    padding-top: rem($i,.5);
  }
  .pb-#{$i} {
    padding-bottom: rem($i,.5);
  }
}

@each $bp, $breakpoint in $breakpoints {
  @include media($bp) {
    @for $i from 0 through 18 {
      .pt-#{$bp}-#{$i} {
        padding-top: rem($i,.5);
      }
      .pb-#{$bp}-#{$i} {
        padding-bottom: rem($i,.5);
      }
    }
  }
}