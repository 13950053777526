$ham-line-color1: color(blue);
$ham-line-color2: color(white);
$ham-line-radius: 0; //px or %
$ham-line-height: 4;
$ham-size: 40;
$ham-radius: 0; //px or %;
$ham-bg-color: transparent;
$ham-transition: .3s;
$ham-line-count: 3;

$pxper: 'px' '%';
$LINE3: $ham-line-count == 3;
$LINE2: $ham-line-count == 2;


.hamburger {
  position: fixed;
  top: 16.5px;
  right: 11px;
  z-index: 10000;
  width: 55px;
  height: 55px;
  cursor: pointer;
  outline: none;
  &__box {
    position: relative;
    display: block;
    width: px($ham-size);
    height: 25px;
    margin: 0 auto;
    background: $ham-bg-color;
    @if $ham-radius > 0 and index($pxper, unit($ham-radius)) {
      border-radius: $ham-radius;
    }
  }
  &__text {
    width: 100%;
    margin-top: 6px;
    font-size: 12px;
    line-height: 1;
    color: color(blue);
    @include flex;
    &:empty {
      display: none;
    }
    .hamburger.is-open & {
      color: color(white);
    }
  }
  &__line {
    display: block;
    width: px($ham-size * .8);
    height: px($ham-line-height);
    background: $ham-line-color1;
    @if $ham-line-radius > 0 and index($pxper, unit($ham-line-radius)) {
      border-radius: $ham-line-radius;
    }
    @if $LINE3  {
      @include position;
      transition: background-color $ham-transition ease-in-out,transform $ham-transition ease-in-out, margin-top $ham-transition $ham-transition ease-in-out;
    }
    @if $LINE2 {
      @include position(x);
      transition: background-color $ham-transition ease-in-out,transform $ham-transition ease-in-out, top $ham-transition $ham-transition ease-in-out;
    }
    &--01 {
      @if $LINE3 { margin-top: px($ham-size / 4); }
      @if $LINE2 {
        top: percentage(1/3);
        transform: translate(-50%,-50%);
      }
    }
    &--02 {
      @if $LINE2 { display: none;}
    }
    &--03 {
      @if $LINE3 { margin-top: px($ham-size / 4,-1); }
      @if $LINE2 {
        top: percentage(2/3);
        transform: translate(-50%,-50%);
      }
    }
  }
}
.is-open.hamburger {
  .hamburger {
    &__line {
      &--01,&--03 {
        margin-top: 0;
        pointer-events: auto;
        background: $ham-line-color2;
        @if $LINE3 { transition: transform $ham-transition $ham-transition ease-in-out, margin-top $ham-transition ease-in-out; }
        @if $LINE2 {
          transition: transform $ham-transition $ham-transition ease-in-out, top $ham-transition ease-in-out;
          top: 50%;

        }
      }
      &--01 {
        transform: translate(-50%,-50%) rotate(45deg) scaleX(1.2);
      }
      &--02 {
        pointer-events: none;
        background-color: rgba(#fff,0);
      }
      &--03 {
        transform: translate(-50%,-50%) rotate(-45deg) scaleX(1.2);
      }
    }
  }
}