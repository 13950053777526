* {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  word-wrap: inherit;
  border: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 62.5%; // 10px
  line-height: 1;
  letter-spacing: .15em;
  word-wrap: break-word;
  font-feature-settings: 'palt';
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  @include iehack {
    letter-spacing: .05em;//IEではfont-feauture-settingsが効かないので
  }
}

body {
  overflow-x: hidden;
  text-align: left;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: 1.6;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
}

main,
aside,
footer,
figure,
figcaption,
article,
section {
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 16px;
}

input {
  border-radius: 0;
}

button {
  background-color: transparent;
  @media (hover: hover) {
    cursor: pointer;
  }
  @include iehack {
    cursor: pointer;
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

@media (min-width: 600px) and (hover: hover) {
  a[href^='tel:'] {
    pointer-events: none;
  }
}
a[href^='tel:'] {
  @include iehack {
    pointer-events: none;
  }
}

[class$='__iframe'], [class*='__iframe '] {
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.iframe {
  @include pt;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@include media(xs) {
  .span {
    display: inline;
  }
}
@include media(sm) {
  .span {
    display: inline;
  }
}
@include media(md) {
  .span {
    display: inline;
  }
}
@include media(lg) {
  .span {
    display: inline;
  }
}

@include media(xs,max) {
  .span {
    display: inline;
  }
}
@include media(sm,max) {
  .span {
    display: inline;
  }
}
@include media(md,max) {
  .span {
    display: inline;
  }
}
@include media(lg,max) {
  .span {
    display: inline;
  }
}