
// ページコンテナー
.page-container{
  margin: 50px 0;
  // ページ内見出
  &_heading{
    font-size: 24px;
    @include flex;
  }
  // ページ内テキスト
  &_text{
    font-size: 16px;
  }
}

//ページインナー
.page-inner{
  padding: 0 20px;
  margin: 50px auto;

  // ページ内見出
  &_heading{
    font-size: 24px;
    @include flex;
  }

  // ページ内テキスト
  &_text{
    font-size: 16px;
  }
}
