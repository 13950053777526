%svg-common {
  background: url('../img/sprites/sprite.view.svg') no-repeat;
}

.svg-about {
  @extend %svg-common;
  background-position: 99.84861063632368% 0;
}

.svg-about-dims {
  width: 107.23px;
  height: 64.32px;
}

.svg-anecdote {
  @extend %svg-common;
  background-position: 32.33782233739751% 98.08108108108108%;
}

.svg-anecdote-dims {
  width: 76.61px;
  height: 80px;
}

.svg-contact {
  @extend %svg-common;
  background-position: 97.35263308256357% 27.22078801472766%;
}

.svg-contact-dims {
  width: 101.98px;
  height: 65.71px;
}

.svg-disciple {
  @extend %svg-common;
  background-position: 0 99.9357444464843%;
}

.svg-disciple-dims {
  width: 76.12px;
  height: 84.12px;
}

.svg-footer {
  @extend %svg-common;
  background-position: 0 0;
}

.svg-footer-dims {
  width: 204.46px;
  height: 217.74px;
}

.svg-home {
  @extend %svg-common;
  background-position: 64.39413103971668% 95.8447046394929%;
}

.svg-home-dims {
  width: 74.82px;
  height: 74.82px;
}

.svg-notice {
  @extend %svg-common;
  background-position: 95.45284780578899% 55.994315735078786%;
}

.svg-notice-dims {
  width: 97.8px;
  height: 69.78px;
}

