// ABOUT
.keifu_img{
  &-IE{
    display: none;
    @include iehack{
      display: block;
    }
  }
}

.about_img{
  justify-content: center;
  &.row{
    @include gap(20, 20)
 }
  &-big{
    @media (max-width:928px){
      width: 100%;
    }
  }
  &-small{
    @media (max-width:928px){
      width: 50%;
    }
  }
}
.about-text_colums{
  font-size: 14px;
  columns: 1;
  text-align: justify;
  @include media(sm){
    columns: 2;
  }
}
.font-italic{
  font-style: italic;
  -webkit-transform: rotate(.25deg);
  transform: rotate(.25deg);
}
.about-caption {
  font-size: 13px;
}
