.hidden {
  visibility: hidden !important;
}

.none {
  display: none !important;
}

@include media(xs,max) {
  .sp-none {
    display: none !important;
  }
}

@include media(sm) {
  .pc-none {
    display: none !important;
  }
}

@include media(sm,max) {
  .tb-none {
    display: none !important;
  }
}

.ie-none {
  @include iehack{
    display: none !important;
  }
}

.max30{
  @include fz(16, 30);
  @include media(sm, max){
    line-height: line-height(20,16);
  }
}
.max40{
  @include fz(29, 40);
}

.color-red {
  color: color(red);
}