// ボタンの設定
%btn {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 60px;
  font-size: 18px;
  @include flex;
  @include hover;
}

.btnBR{
  @extend %btn;
  @include btn-color(brown,white);
  @include btn-hover(brown,white,none);
}
.btnBR.arrowWH{
  @extend %btn;
  @include btn-color(brown,white);
  @include btn-hover(brown,white,brown);
}
.btnBL{
  @extend %btn;
  @include btn-color(blue,white);
  @include btn-hover(blue,white,none);
}
.btnSK{
  @extend %btn;
  border: 1px solid black;
  @include btn-color(none,black);
  @include btn-hover(white,blue,white);
}
.btnSKW{
  @extend %btn;
  border: 1px solid color(white);
  @include btn-color(none,white);
  @include btn-hover(brown,white,brown);
}
.arrowWH{
  @include arrow(white);
}
.arrowBK{
  @include arrow(black);
}

.prevBtn, .nextBtn {
  position: absolute;
  top: 50%;
  z-index: 100000;
  width: 25px;
  height: 25px;
  background: rgba(#163257, .96);
  transform: translateY(-50%);
  
  @include media(sm) {
    top: 50%;
    width: 50px;
    height: 50px;
  }
  

  &::before {
    position: absolute;
    top: 50%;
    display: block;
    width: 12.5px;
    height: 12.5px;
    content: '';
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    
    @include media(sm) {
      width: 25px;
      height: 25px;
    }
  }
}

.prevBtn {
  left: 16px;

  @include media(sm) {
    left: 20px;
  }
  
  &::before {
    left: 60%;
    transform: translate(-50%,-50%) rotate(135deg);
  }
}
.nextBtn {
  right: 15px;

  @include media(sm) {
    right: 20px;
  }
  
  &::before {
    left: 40%;
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}