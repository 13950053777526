.t-section1 {
  .naname-heading {
    margin-bottom: 0;
  }
  .naname-heading__text {
    @include fz(24,30);
  }
  &__container {
    @include media(sm) {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &__content {
    max-width: 460px;
    margin: 50px auto 0;
    @include media(sm) {
      flex-shrink: 0;
      width: 50%;
      padding-left: 20px;
      margin-top: -90px;
    }
  }
  &__img {
    width: 100%;
    @include pt(460,480);
    > img {
      @include position;
      width: auto;
      max-width: initial;
      height: 100%;
    }
  }
  &__caption {
    margin-top: 10px;
    @include fz(12,13.5);
  }
  &__text {
    max-width: 460px;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 1.6rem;
    line-height: line-height(40,16);
    letter-spacing: 0;
    @include media(sm) {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
    }
  }
  &__btn {
    max-width: 310px;
    margin: 0 auto;
    margin-top: 50px;
    @include media(sm) {
      margin-top: auto;
      margin-bottom: 20px;
    }
    &::after {
      background: transparent;
    }
  }
}
.t-section2 {
  margin-top: 70px;
  @include media(sm) {
    display: flex;
    padding: 0 40px;
    margin-top: 150px;
  }
  &__video {
    // @include media(sm) {
    //   width: 58%;
    // }
    @include pt(585,340);
  }
  &__text {
    max-width: 460px;
    margin: 25px auto 0;
    font-size: 1.6rem;
    line-height: line-height(40,16);
    @include media(sm) {
      text-align: center;
      @include flex;
    }
  }
}
.t-section3 {
  padding: 50px 10px 65px;
  margin-top: 65px;
  color: color(white);
  background: color(brown);
  $this: &;
  @include media(sm) {
    display: flex;
    padding: 45px 35px 45px 5px;
    margin: 65px 0 0;
  }
  @media (min-width: 1080px) {
    margin: 65px -40px 0;
  }
  &__title {
    @include fz(26,40);
    padding-bottom: 20px;
    border-bottom: 1px solid currentColor;
    @include media(sm) {
      width: 22%;
      text-align: center;
      border-right: 1px solid currentColor;
      border-bottom: none;
    }
  }
  &__container {
    padding-top: 35px;
    font-size: 1.6rem;
    @include media(sm) {
      width: 78%;
      padding-top: 0;
      padding-left: 50px;
    }
    > a:not(:first-of-type) {
      #{$this}__date {
        margin-top: 15px;
        @include media(sm) {
          margin-top: 0;
        }
      }
    }
    > a:not(:nth-last-of-type(2)) {
      border-bottom: 1px dotted currentColor;
    }
  }
  &__link {
    display: block;
    padding: 0 5px;
    @include media(sm) {
      @include flex(y);
      height: 60px;
      &--height-half {
        height: 40px;
      }
    }
    &:last-of-type{
      margin-bottom: 20px;
    }
  }
  &__date {
    display: block;
    @include media(sm) {
      line-height: 1.6;
    }
  }
  &__heading {
    margin-bottom: 10px;
    line-height: 1.6;
    @include media(sm) {
      margin-bottom: 0;
      margin-left: 40px;
    }
  }
  &__btn {
    max-width: 240px;
    margin: 0 auto;
    @include media(sm) {
      max-width: 310px;
    }
  }
}
.t-section4 {
  margin-top: 70px;
  @include media(sm) {
    margin-top: 150px;
  }
  .naname-heading__text {
    @include fz(27,40);
  }
  &__container {
    @include gap(30,15);
  }
  &__item {
    flex: 0 0 percentage(1/1);
    width: 100%;
    font-size: 1.6rem;
    @include media(xs) {
      flex: 0 0 percentage(1/2);
      width: percentage(1/2);
    }
    @include media(sm) {
      flex: 0 0 percentage(1/3);
      width: percentage(1/3);
    }
  }
  &__link {
    display: block;
    @include hover;
  }
  &__img {
    @include pt(338,189);
    > img {
      top: 0;
      @include position(x);
    }
  }
  &__text {
    display: block;
    margin-top: 15px;
    text-align: center;
  }
}